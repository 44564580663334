import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import metroimage from '../../assets/metroimage.png';
import { DISPLAY_EVENT_STATUS } from '../../common/constants/displayEventStatus';
import { EVENT_STATUS } from '../../common/enums/eventStatus.enum';
import ApprovalModal from '../../components/ApprovalModal/ApprovalModal';
import { deleteEvent } from '../../redux/actions/EventAction/eventActions';
import { reset } from '../../redux/actions/FormAction/FormAction';
import EventService from '../../services/EventService';
import RegisterButton from '../../shared/Buttons/RegisterButton';
import SubmitButton from '../../shared/Buttons/SubmitButton';
import ViewEntries from '../../shared/Buttons/ViewEntries';
import ViewLeaderBoard from '../../shared/Buttons/ViewLeaderBoard';

import './MyEvents.scss';

function Events(props: any) {
  const [coverPic, setcoverPic] = useState(null);
  const [profilePic, setprofilePic] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { event, registered, organized } = props;
  const {
    isSubmitted,
    isOrganizer,
    isJudge,
    isRegistered,
    isRegistrationEnabled,
    isSubmitEnabled,
    isMultipleSubmissionEnabled,
  } = event;

  const startDate = new Date(event.startDate).toUTCString().slice(5, 16);
  const endDate = new Date(event.endDate).toUTCString().slice(5, 16);

  const eventStatus = (eventStatus: string) => {
    if (eventStatus === EVENT_STATUS.APPROVED) {
      return DISPLAY_EVENT_STATUS.APPROVED;
    }

    if (eventStatus === EVENT_STATUS.REQUESTED) {
      return DISPLAY_EVENT_STATUS.REQUESTED;
    }

    if (eventStatus === EVENT_STATUS.ACTION_REQUIRED) {
      return DISPLAY_EVENT_STATUS.ACTION_REQUIRED;
    }

    if (eventStatus === EVENT_STATUS.REJECTED) {
      return DISPLAY_EVENT_STATUS.DISCARD;
    }

    return DISPLAY_EVENT_STATUS.DRAFT;
  };

  let convertedStatus = event.commonStatus;

  if (isOrganizer && !registered) {
    convertedStatus = eventStatus(event.status);
  }

  const displayEditiMenu = () => {
    setShowMenu((previousValue) => !previousValue);
  };

  const onEdit = (eventId: any) => {
    dispatch(reset());
    navigate(`/edit/${eventId}`);
  };

  const onDelete = (eventId: string) => {
    dispatch(deleteEvent(eventId));
  };

  const checkStatus = () => {
    if (convertedStatus === DISPLAY_EVENT_STATUS.APPROVED) {
      return false;
    }
    return true;
  };

  const setStatus = {
    backgroundColor:
      convertedStatus === DISPLAY_EVENT_STATUS.ACTION_REQUIRED
        ? '#FF9E16'
        : convertedStatus === DISPLAY_EVENT_STATUS.DISCARD
          ? '#E64848'
          : convertedStatus === DISPLAY_EVENT_STATUS.APPROVED ||
              convertedStatus === DISPLAY_EVENT_STATUS.ONGOING ||
              convertedStatus === DISPLAY_EVENT_STATUS.REGISTRATION_STARTED ||
              convertedStatus === DISPLAY_EVENT_STATUS.SUBMISSION_STARTED ||
              convertedStatus === DISPLAY_EVENT_STATUS.REGISTERED ||
              convertedStatus === DISPLAY_EVENT_STATUS.SUBMITTED
            ? '#24C142'
            : convertedStatus === DISPLAY_EVENT_STATUS.REQUESTED
              ? '#FF9E16'
              : convertedStatus === DISPLAY_EVENT_STATUS.DRAFT ||
                  convertedStatus === DISPLAY_EVENT_STATUS.REGISTRATION_CLOSED ||
                  convertedStatus === DISPLAY_EVENT_STATUS.SUBMISSION_CLOSED
                ? '#7e7e7e'
                : convertedStatus === DISPLAY_EVENT_STATUS.UPCOMING
                  ? '#00ADF0'
                  : '#7e7e7e',
    top: '16px',
    left: '24px',
  };

  useEffect(() => {
    Promise.all([
      EventService.downloadImage(event.coverPic),
      EventService.downloadImage(event.profilePic),
    ]).then((response: any) => {
      if (typeof response[0].data === 'object') {
        setcoverPic(response[0].data.img);
      }
      if (typeof response[1].data === 'object') {
        setprofilePic(response[1].data.img);
      }
    });
  }, []);
  return (
    <>
      <ApprovalModal show={modalShow} onHide={() => setModalShow(false)} eventId={event.id} />
      <div className="col-12 col-md-6 col-lg-4 mb-3 rounded wrapper">
        <div className="card w-100 position-relative">
          <div
            className="position-absolute px-3 text-light rounded-pill event-status-title"
            style={setStatus}
          >
            {convertedStatus}
          </div>
          {checkStatus() && isOrganizer && (
            <div>
              <div
                className="position-absolute edit-events text-muted dropdown"
                onClick={displayEditiMenu}
                role="button"
                tabIndex={0}
              >
                <i className="bi bi-three-dots-vertical" />
              </div>

              {showMenu && (
                <div className="position-absolute menu-box d-flex flex-column">
                  {convertedStatus === DISPLAY_EVENT_STATUS.APPROVED ||
                  convertedStatus === DISPLAY_EVENT_STATUS.REQUESTED ||
                  convertedStatus === DISPLAY_EVENT_STATUS.DISCARD ? null : (
                    <button
                      type="button"
                      className="btn text-primary menu-box_button text-start"
                      onClick={() => {
                        setModalShow(true);
                        displayEditiMenu();
                      }}
                    >
                      <i className="bi bi-send px-2" />
                      Send For Approval
                    </button>
                  )}
                  {convertedStatus !== DISPLAY_EVENT_STATUS.DISCARD && (
                    <button
                      type="button"
                      className="btn text-primary menu-box_button text-start"
                      onClick={() => {
                        onEdit(event.id);
                        displayEditiMenu();
                      }}
                      disabled={convertedStatus === DISPLAY_EVENT_STATUS.REQUESTED}
                    >
                      <i className="bi bi-pencil-square px-2" />
                      Edit Event
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn text-danger menu-box_button text-start"
                    onClick={() => {
                      onDelete(event.id);
                      displayEditiMenu();
                    }}
                    disabled={convertedStatus === DISPLAY_EVENT_STATUS.REQUESTED}
                  >
                    <i className="bi bi-trash-fill px-2" />
                    Delete Event
                  </button>
                </div>
              )}
            </div>
          )}
          <Link to={`/event/${event.id}`} state={{ from: 'dashboard' }}>
            <img src={coverPic || metroimage} alt="img" className="card-img-top" height={156} />
          </Link>
          <div className="card-body">
            <div className="profile d-flex">
              <div className="profile_div">
                <img src={profilePic || metroimage} alt="" />
              </div>
              {event.status === EVENT_STATUS.APPROVED && (
                <div className="mt-5 mt-md-0 ms-md-auto card-button">
                  {!isOrganizer &&
                    !organized &&
                    !isJudge &&
                    !isRegistered &&
                    isRegistrationEnabled && <RegisterButton eventId={event.id} />}
                  {!organized &&
                    !isOrganizer &&
                    !isJudge &&
                    (isMultipleSubmissionEnabled || !isSubmitted) &&
                    (isRegistered ||
                    isSubmitEnabled) && <SubmitButton eventId={event.id} />}
                  {(isOrganizer || isJudge) && !registered && <ViewEntries id={event.id} />}
                  {!isOrganizer &&
                    !isJudge &&
                    (isRegistered ||
                    isSubmitted) &&
                    event.isLeaderboardEnabled && <ViewLeaderBoard eventId={event.id} />}
                </div>
              )}
            </div>
            <Link to={`/event/${event.id}`} state={{ from: 'dashboard' }}>
              <div className="mt-md-5">
                <h6
                  className="card-title mt-4 mb-0 text-capitalize"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={event.name}
                >
                  {event.name}
                </h6>

                <div className="d-flex justify-content-between mt-2 align-items-center">
                  <p className="card-text pb-0 count-font">
                    Created by <span>{event.createdBy}</span>
                  </p>
                  <p className="fw-bold p-0 font-text">
                    {startDate} - {endDate}
                  </p>
                </div>
                {/* <p className="text-muted font-text mb-0">
                  Participants
                  <span className="text-white m-1 p-2">
                    <span className="count">{event.numberOfParticipants}</span>
                  </span>
                </p> */}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
