import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import FormButton from '../../components/FormButton/FormButton';
import FormGenerator from '../../components/FormGenerator/FormGenerator';
import Toggle from '../../components/ToggleSwitch/Toggle';
import { nextStep, setRegistraionDetail } from '../../redux/actions/FormAction/FormAction';
import EventService from '../../services/EventService';
import timeconv from '../../utils/timeConversion';

import './Registration.scss';

function Registration(props: any) {
  const [count, setCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { registrationTemplate, eventId, isEditMode } = useSelector(
    (state: any) => state.rootReducer.EventFormReducer,
  );
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const onCancel = () => {
    if (Object.keys(registrationTemplate).length !== 0) {
      setModalShow(true);
    }
  };

  const onHandleChange = (item: any) => {
    if (count % 2 !== 0) {
      setShow(false);
    }
    setState(item.selection);
    setCount((prevCount) => prevCount + 1);
  };

  const onSave = () => {
    let registrationAnswers = {};
    if (!toggle) {
      registrationAnswers = {
        eventId,
        template: {},
        isEnabled: false,
      };
      dispatch(setRegistraionDetail({ isEnabled: false, template: {} }));
    } else {
      dispatch(
        setRegistraionDetail({
          ...registrationTemplate,
          startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
          endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
        }),
      );
      registrationAnswers = {
        ...registrationTemplate,
        startDate: timeconv.formatDateToLocalISOStartDate(state.startDate),
        endDate: timeconv.formatDateToLocalISOEndDate(state.endDate),
      };
      toast.success('Successfully created Registration Form');
    }
    setLoading(true);
    EventService.updateRegistrationForm(eventId, registrationAnswers)
      .then((response: any) => {
        setLoading(false);
        dispatch(nextStep());
      })
      .catch((error: any) => {
        console.error('error', error);
      });
  };

  useEffect(() => {
    if (Object.keys(registrationTemplate).length !== 0 || isEditMode) {
      EventService.getRegistrationFormById(eventId)
        .then((res: any) => {
          if (Object.keys(res.data.message.template).length === 0) {
            setToggle(false);
          } else {
            const dateUpdate = {
              startDate: new Date(res.data.message.startDate),
              endDate: new Date(res.data.message.endDate),
              key: 'selection',
            };
            setState(dateUpdate);
          }
          window.scrollTo(0, 0);
          return Promise.resolve(res.data.message.template.task_data);
        })
        .catch((error: any) => {
          console.error('error', error);
          window.scrollTo(0, 0);
          return Promise.resolve([]);
        });
    } else if (registrationTemplate.isEnabled === false) {
      setToggle(false);
    }
  }, [setState, setToggle, eventId]);

  return (
    <div className="registration">
      <ConfirmModal show={modalShow} onHide={() => setModalShow(false)} />
      <span className="me-2">Registration template</span>
      <Toggle ischeck={toggle} setToggle={setToggle} />
      <div>
        {toggle ? (
          <>
            <Form.Group className="my-3">
              <Form.Label className="me-2 mb-0">Registration Date Range</Form.Label>
              <div
                className="rdrDateDisplay w-25 ms-0"
                style={{ color: 'rgb(61, 145, 255);' }}
                onClick={() => setShow(!show)}
              >
                <span className="rdrDateInput rdrDateDisplayItem text-center">
                  <input
                    placeholder="start date"
                    value={`${state?.startDate.toLocaleDateString()} - ${state?.endDate.toLocaleDateString()}`}
                    type="text"
                  />
                </span>
              </div>
              <div className="Registration-Daterange-picker">
                {show ? (
                  <DateRangePicker
                    onChange={(e: any) => onHandleChange(e)}
                    moveRangeOnFirstSelection={false}
                    showDateDisplay
                    months={2}
                    ranges={[state]}
                    direction="horizontal"
                    minDate={moment().toDate()}
                  />
                ) : null}
              </div>
            </Form.Group>
            <FormGenerator isRegistration={toggle} />
            <FormBuilder isRegistration={toggle} />
          </>
        ) : null}
        <FormButton onSave={onSave} onCancel={onCancel} loading={loading} />
      </div>
    </div>
  );
}

export default Registration;
